import type { ReadonlyURLSearchParams } from 'next/navigation';

export function parseUrlQueryToRecord(
  query: ReadonlyURLSearchParams
): Record<string, string> {
  return Array.from(query.entries()).reduce<Record<string, string>>(
    (obj, [key, value]) => {
      if (Array.isArray(value)) {
        obj[key] = value[0]; // pick the first
      } else if (typeof value === 'string') {
        obj[key] = value;
      } else {
        obj[key] = '';
      }
      return obj;
    },
    {}
  );
}
